




































































































































































































































import {
  getDeviceLink,
  getShadow,
  removeDeviceLink,
  updateShadow
} from '../../api/admin';
import { Vue, Component, Prop } from 'vue-property-decorator';
import JsonEditorComponent from '../../components/JsonEditorComponent.vue';
import type { DeviceLink, DeviceShadow } from '../../typings';
import {
  mdiAccountOff,
  mdiAccountSearch,
  mdiContentSave,
  mdiFactory,
  mdiFlagPlus,
  mdiPaperRoll,
  mdiRefresh
} from '@mdi/js';
import type { NavigationGuardNext, Route } from 'vue-router';
import { Hub } from '@aws-amplify/core';
import { ADMIN_USER_GROUP } from '../../constants';
import Auth from '@aws-amplify/auth';

@Component({
  components: {
    JsonEditorComponent
  }
})
export default class ShadowEditorView extends Vue {
  private readonly ADMIN_USER_GROUP: typeof ADMIN_USER_GROUP =
    ADMIN_USER_GROUP;
  private readonly mdiRefresh: string = mdiRefresh;
  private readonly mdiContentSave: string = mdiContentSave;
  private readonly mdiFactory: string = mdiFactory;
  private readonly mdiAccountSearch: string = mdiAccountSearch;
  private readonly mdiFlagPlus: string = mdiFlagPlus;
  private readonly mdiPaperRoll: string = mdiPaperRoll;
  private readonly mdiAccountOff: string = mdiAccountOff;

  private viewportHeight: number = window.innerHeight;

  private value: string = '';
  private changes: string = '';
  private dialogShow: boolean = false;
  private confirmUnsaved: null | NavigationGuardNext | 'reload' = null;
  private sub: string | null = null;
  private loading: boolean = true;
  private userGroups: string[] = [];

  @Prop({
    type: String,
    required: true,
    validator: (value: string): boolean => !!value
  })
  public uid!: string;

  private async created(): Promise<void> {
    this.userGroups =
      (
        await Auth.currentSession().catch((): undefined => undefined)
      )?.getIdToken()?.payload?.['cognito:groups'] || [];
  }

  private mounted(): void {
    this.getShadow();
  }

  private showError(message: string): void {
    Hub.dispatch('appAlert', {
      event: 'error',
      message
    });
  }

  private async getShadow(): Promise<void> {
    this.loading = true;
    getShadow(this.uid)
      .then((shadow: DeviceShadow): void => {
        delete shadow.version;
        this.value = JSON.stringify(shadow, undefined, 2);
        this.changes = this.value;
      })
      .then(
        (): Promise<DeviceLink | null> =>
          getDeviceLink(this.uid).catch((): null => null)
      )
      .then(
        (link: DeviceLink | null): void => void (this.sub = link?.sub || null)
      )
      .catch((error: Error): void => this.showError(error.message))
      .finally((): void => void (this.loading = false));
  }

  private async updateShadow(): Promise<void> {
    this.loading = true;
    try {
      const shadow: DeviceShadow = JSON.parse(this.changes);
      if (this.userGroups.includes(ADMIN_USER_GROUP)) {
        await updateShadow(this.uid, shadow);
      } else {
        await updateShadow(this.uid, {
          state: {
            desired: {
              log: shadow?.state?.desired?.log || undefined
            }
          }
        });
      }
      this.getShadow();
    } catch (e) {
      this.loading = false;
      this.showError(e.message);
    }
  }

  private setVerification(): void {
    this.loading = true;
    try {
      const shadow: DeviceShadow = JSON.parse(this.value);
      shadow.state.reported.verification = true;
      this.changes = JSON.stringify(shadow);
      this.updateShadow();
    } catch (e) {
      this.loading = false;
      this.showError(e.message);
    }
  }

  private removeDeviceLink(): void {
    if (!this.sub || !this.uid) {
      return;
    }
    this.dialogShow = false;
    this.loading = true;
    removeDeviceLink(this.sub, this.uid)
      .then((): Promise<void> => this.getShadow())
      .catch((error: Error): void => {
        this.loading = false;
        this.showError(error.message);
      });
  }

  private get isDirty(): boolean {
    return this.changes !== this.value;
  }

  private get isValid(): boolean {
    try {
      JSON.parse(this.changes);
    } catch (e) {
      return false;
    }
    return true;
  }

  private continueUnsaved(): void {
    if (!this.confirmUnsaved) {
      return;
    } else if (this.confirmUnsaved === 'reload') {
      this.getShadow();
    } else {
      this.confirmUnsaved();
    }
    this.confirmUnsaved = null;
  }

  private beforeRouteUpdate(
    _to: Route,
    _from: Route,
    next: NavigationGuardNext
  ): void {
    if (this.isDirty) {
      this.confirmUnsaved = next;
      return;
    }
    next();
  }

  private beforeRouteLeave(
    _to: Route,
    _from: Route,
    next: NavigationGuardNext
  ): void {
    if (this.isDirty) {
      this.confirmUnsaved = next;
      return;
    }
    next();
  }
}
