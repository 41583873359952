




















import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import ace, { Ace } from 'ace-builds';
import 'ace-builds/webpack-resolver';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-xcode';
import '../styles/ace-theme';

@Component
export default class JsonEditorComponent extends Vue {
  private editor?: Ace.Editor;

  @Prop({ type: String, default: '' })
  private readonly value!: string;

  @Prop({ type: Boolean, default: false })
  private readonly disabled!: boolean;

  @Prop({ type: Number, default: Infinity })
  private readonly maxHeight!: number;

  @Prop({ type: Boolean, default: true })
  private readonly dark!: boolean;

  @Watch('value', { deep: true })
  private onValueChanged(value: string): void {
    if (this.editor?.getValue() !== value) {
      this.editor?.setValue(value ?? '');
    }
  }

  @Watch('disabled')
  private onDisabledChanged(disabled: boolean): void {
    if (this.editor) {
      this.editor.setOptions({
        readOnly: disabled,
        highlightActiveLine: !disabled,
        highlightGutterLine: !disabled
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.editor.renderer as any).$cursorLayer.element.style.opacity =
        disabled ? 0 : 1;
    }
  }

  @Watch('maxHeight')
  private onHeightChanged(maxPixelHeight: number): void {
    if (this.editor) {
      this.editor.renderer.setOptions({
        maxPixelHeight
      });
    }
  }

  @Watch('dark')
  private onThemeChanged(dark: boolean): void {
    if (this.editor) {
      this.editor.setTheme(
        dark ? 'ace/theme/atomOneDark' : 'ace/theme/xcode'
      );
    }
  }

  private mounted(): void {
    this.editor = ace.edit(this.$refs.editor as HTMLElement, {
      readOnly: this.disabled,
      highlightActiveLine: !this.disabled,
      highlightGutterLine: !this.disabled,
      showPrintMargin: false,
      fontSize: 13,
      tabSize: 2,
      useSoftTabs: true,
      maxLines: Infinity,
      minLines: Infinity,
      value: this.value ?? '',
      mode: 'ace/mode/json',
      theme: this.dark ? 'ace/theme/atomOneDark' : 'ace/theme/xcode',
      animatedScroll: false,
      maxPixelHeight: this.maxHeight
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this.editor.renderer as any).$cursorLayer.element.style.opacity = this
      .disabled
      ? 0
      : 1;

    this.editor.on(
      'input',
      (): void => void this.$emit('input', this.editor?.getValue())
    );
  }
}
